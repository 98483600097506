import { Box, Button, Divider, HStack, Heading, Image, Link, SimpleGrid, Skeleton, Stack, Text } from '@chakra-ui/react'
import { PageProps, graphql } from 'gatsby'
import React, { useState } from 'react'

import AddToCart from '../components/AddToCart'
import Layout from '../components/Base/Layout'
import { ProductBreadcrumb } from '../components/Shop/ProductBreadcrumbs'
import {RelatedProductsCard} from '../components/Product/RelatedProductCard'
import { SEO } from '../components/Base/Seo'
import useAuth from '../components/useAuth'

const Product: React.FC<ProductProps> = ({ data }) => {

  const {
    wpProduct: {
      name, databaseId, shortDescription, price, regularPrice,
    },
    wpProduct: {
      image
    }, 
    wpProduct: {
      galleryImages: {
        images
      }
    },
    wpProduct: {
      productCategories: {
        catName
      }
    }, 
    wpProduct: {
      productTags: {
        brandName
      }
    },
     wpProduct: {
      // attributes: {
      //   attr
      // }
    },
    wpProduct: {
      related: {
         relatedProducts
       }
     }
  } = data
    console.log(data)

  // Price without ,00 in the end 
  var priceSub = regularPrice?.slice(0, - 3);

  const categoryName = catName.map((item: any) => {
    {item.name}
  })

  // Function for getting the image src
  const [currentImage, setCurrentImage] = useState(data.wpProduct.image?.sourceUrl)

  const getImageSrc = (e: any) => {
    
    setCurrentImage(e.target.src)
   
  }

  const { loggedIn, user } = useAuth();

  return (
    <Layout>

      <Box as="section" bg="white" px={{ base: '0', lg: '12' }} py={{ base: '6', lg: '12' }}>
        
        <Box display="flex" flexFlow="column" alignItems="flex-start" justifyContent="center" mx="auto" px={{ base: '4', md: '8', lg: '12' }} bg="white" minHeight="656px" maxWidth="container.lg"   >
          
          {/* BREADCRUMBS */}
          <HStack>
            <ProductBreadcrumb shop="Home" category={catName.map((item: any) => { return item.name })}
              categoryHref={catName.map((item: any) => { return item.slug })} ></ProductBreadcrumb>
          </HStack>
          
          <Stack minWidth="100%" direction={{ base: 'column-reverse', lg: 'row' }} spacing={{ base: '6', lg: '12', xl: '16' }} py="14" justifyContent="space-between" pb="10">
           
            <Stack spacing={{ base: '6', lg: '8' }} minW="50%" justify="center" >
              
              <Stack spacing={{ base: '3', md: '4' }} >
                <Stack spacing="3">
                  
                      {/* name */}
                      <Heading size="lg" fontWeight="bold" color="gray.800">
                          {name}
                      </Heading>
                      </Stack>

                      {/* price */}
                      <Text color="gcsblue.600" fontSize="20px" fontWeight="700" > {priceSub} </Text>
                      
                      {/* description */}
                      {shortDescription && ( <Text color="gray.600" dangerouslySetInnerHTML={{ __html: shortDescription }}/>)}

                      {/* category */}
                      {catName.map((item: any) => (
                        <HStack>
                          <Text color="gray.600">Category: </Text>
                          <Text color="base.baseColor" fontWeight="700" > {item.name} </Text>
                        </HStack>
                      ))}
                
                      {/* brand */}
                      {/* {brandName.map((item: any) => (
                        <HStack>
                          <Text color="gray.600">Brand: </Text>
                          <Text color="base.baseColor" fontWeight="700"> {item.name} </Text>            
                        </HStack>
                      ))} */}
                
                 <HStack>
                  <Text color="gray.600">Brand: </Text>
              
                    {brandName.map((item:any, index, array) => {
                      const lastElement = array.length - 1;
                      
                      if (lastElement == index) {
                        return <Text color="base.baseColor" fontWeight="700" > {item.name} </Text>
                      } else {
                        return <Text color="base.baseColor" fontWeight="700" > {item.name}, </Text>
                      }
                    }
                          
                  )}
                </HStack>
                  </Stack>
              
                   {/* Add to cart */}
                   {!loggedIn ?
                      (<Link className="chakra-button css-1oloaee" href='/log-in'  _hover={{textDecoration: "none !important"}} display='block' margin="0px auto !important" ><Button colorScheme="gcsblue" variant="solid" size="lg" mt="16px !important" > Sign In To Order</Button></Link>) :
                      (<AddToCart productId={databaseId} />)
                    }
                  </Stack>
                        
                  <Box display="flex" flexFlow="column" minW='50%'>
                    <Image fallback={<Skeleton />} src={currentImage} margin="0" objectFit="contain" borderRadius="8px"></Image>
                    <HStack justifyContent="center" pt="4">
                      {images.slice(0,3).map((image:any) => (
                        <Image fallback={<Skeleton />} src={image.sourceUrl} borderRadius="8px" height="104px" width="104px" margin="0" objectFit="contain" onClick={getImageSrc}></Image>
                      ))}    
                    </HStack>
                  </Box>
                  </Stack>
              
            {/* <Divider></Divider> */}
          
            <Stack py="10">
            {/* <Stack>
              <Heading fontSize='xl' color="blue.500" pb="6"> Additional Information</Heading>
                {attr.map((item: any) => (
                  <HStack>
                    <Box w="143px" mr="8"><Text color="gray.600"> {item.name}: </Text></Box>
                    <Box><Text color="blue.500" fontWeight="700"> { item.options } </Text></Box>
                    
                  </HStack>
                ))}
              </Stack> */}
          </Stack>
          
          <Divider></Divider>

          <Stack py="10" width="100%">
            <Heading fontSize='xl' pb="6"> Related Products </Heading>
            <SimpleGrid columns={{base: 1, sm: 2, lg: 4}} columnGap={{ base: '4', md: '6' }} rowGap={{ base: '8', md: '10' }} >
            {relatedProducts.slice(0,4).map((product:any) => (
              <div key={product.id}>
                <RelatedProductsCard props={product}></RelatedProductsCard>
              </div> 
             ))}
          </SimpleGrid>
          </Stack>
        </Box>
      </Box>

    </Layout>
  )
}

type ProductProps = PageProps<ProductQuery>

type ProductQuery = {
    wpProduct: {
      slug: string
      name: string
      databaseId: number
      shortDescription: string
      price: string
      regularPrice: string
      image?: {
        sourceUrl?: string
      },
      galleryImages: {
        images: []
      },
      productCategories: {
        catName: []
      }, 
      productTags: {
        brandName: []
      }, 
      // attributes: {
      //     attr: []
      // },
      related: {
        relatedProducts: []
      }
  }, 
 
}

export const query = graphql`
  query PRODUCT($slug: String!) { 
    wpProduct(slug: {eq: $slug}) {

      related {
        relatedProducts: nodes {
          name
          slug
          databaseId
          image {
            sourceUrl
          }
          ... on WpSimpleProduct {
            id
            name
            price
            regularPrice
          }
        }
      }

      galleryImages {
        images: nodes {
          sourceUrl
        }
      }
      
      name
      slug
      databaseId
      shortDescription
      image {
        sourceUrl
      }
      productCategories {
        catName: nodes {
          name
          slug
        }
      }
      productTags {
        brandName: nodes {
          name
        }
      }
      ... on WpSimpleProduct {
        id
        name
        price
        regularPrice
      }
      
    }
  }
`

export default Product


export const Head = () => (
  <SEO title="Global Care Supply" description="" />
)
